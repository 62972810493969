import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
  ECaseConfirmDialogComponent,
  EcaseHttpService,
  ECaseSnackBarService,
  ECaseUtils,
  LoginAuthenticationService,
  ParametersService,
  PendingChangesGuard,
  ProjectService,
  RefreshDataService
} from 'synto-common';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {HttpResponse} from '@angular/common/http';

import moment from 'moment';

@Component({
  selector: 'fb-submit-button',
  templateUrl: './fb-submit-button.component.html',
  styleUrls: ['./fb-submit-button.component.scss']
})
export class FbSubmitButtonComponent implements OnInit, OnDestroy {
  @Input() selectedLanguage: any;
  @Input() selectedSectionId: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() enableOutputEvent: boolean;
  @Input() isFormbuilderModeEnabled: boolean;
  @Output() emitOutputEvent = new EventEmitter<any>();
  clickedOneTime: boolean;
  row: any;
  showAdditionalConfirmation = false;
  timer;
  subscription3: Subscription;
  moment;

// Do not remove any services even if it appears not used, some of them are used in eval
  constructor(private translate: TranslateService, private http: EcaseHttpService, private eCaseSnackBarService: ECaseSnackBarService,
              private loginAuthenticationService: LoginAuthenticationService, public dialog: MatDialog, private router: Router,
              private refreshDataService: RefreshDataService, private pendingChangesGuard: PendingChangesGuard,
              private projectService: ProjectService, private parametersService: ParametersService) {
    this.confChange = {};
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.row = {};
    this.moment = moment;
  }

  ngOnInit(): void {
    console.log(this.globalPrj);
    if (this.confChange) {
      if (this.confChange.showAdditionalConfirmation) {
        this.showAdditionalConfirmation = this.confChange.showAdditionalConfirmation;
      }
    }
    if (!(this.bphChange)) {
      this.bphChange = {};
    }
    this.confChange.actions = this.confChange.actions || [];
    if (this.isItemBefore('save', 'submit', this.confChange.actions)) {
      this.subscription3 = this.refreshDataService.submitFormSubscriber().subscribe((data) => {
        if (this.confChange.name === data.name) {
          if (data.prj) {
            this.globalPrj = data.prj;
          }
          this.submitForm(this.confChange.showAdditionalConfirmation);
        }
      });
    }
  }


  isItemBefore(item1: string, item2: string, inputArray: string[]): boolean {
    return (inputArray.indexOf(item1) === -1 || inputArray.indexOf(item2) === -1) ? false : (inputArray.indexOf(item1) < inputArray.indexOf(item2));
  }


  saveAndSubmit(): void {
    if (!this.isFormbuilderModeEnabled) {
      this.clickedOneTime = false;
      this.confChange.operation = this.confChange.url === 'Save and continue' ? 'Save and continue' : 'Save';
      //  this.executeCustomJS();
      if (this.confChange.actions.length === 0 || (this.confChange.actions.length === 1 && this.confChange.actions.indexOf('doNothing') !== -1)) {
        this.submitForm(this.confChange.showAdditionalConfirmation);
      } else {
        if (this.confChange.actions.indexOf('save') !== -1) {
          this.confChange.operation = 'Save';
        } else if (this.confChange.actions.indexOf('saveandcontinue') !== -1) {
          this.confChange.operation = 'Save and continue';
        }

        if (this.isItemBefore('save', 'submit', this.confChange.actions) || this.isItemBefore('saveandcontinue', 'submit', this.confChange.actions)) {
          this.refreshDataService.saveForm(this.confChange);
          this.clickedOneTime = true;
        } else {
          this.submitForm(this.confChange.showAdditionalConfirmation);
        }
      }
    } else {
      this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode', this.selectedLanguage));
    }
  }

  executeCustomJS(): void {
    if (this.confChange.onclickjs) {
      try {
        const globalPrj = this.globalPrj;
        const globalConf = this.globalConf;
        const globalComponent = this;

        eval(this.confChange.onclickjs);
        this.globalPrj = globalPrj;
        this.bphChange = globalComponent.bphChange;
      } catch (e) {
        console.error(e);
      }
    }
  }

  executeFailureJS(): void {
    if (this.confChange.onclickjsfail) {
      try {
        const globalPrj = this.globalPrj;
        const globalConf = this.globalConf;
        const globalComponent = this;

        eval(this.confChange.onclickjsfail);
        this.globalPrj = globalPrj;
        this.bphChange = globalComponent.bphChange;
      } catch (e) {
        console.error(e);
      }
    }
  }

  executeAfterResponseJS(response: any): void {
    if (this.confChange.onclickJsSuccess) {
      try {
        this.globalConf.pendingChangesGuard = this.pendingChangesGuard;
        this.confChange.onclickJsSuccess(this.globalPrj, this.globalConf, this.eCaseSnackBarService, this.http,
          this.router, this.projectService, this.translate, response);
      } catch (e) {
        console.error(e);
      }
    }
  }

  showSnackBar(status, message): void {
    this.eCaseSnackBarService.show((status ? 'success' : 'failure'), message);
  }


  submitForm(hasDialog: boolean): any {
    if(this.confChange.isConfirmEnabled) {
      const confirm = this.dialog.open(ECaseConfirmDialogComponent);
      confirm.componentInstance.confirmMessage = this.confChange.confirmDialogText[this.selectedLanguage];
      confirm.afterClosed().subscribe((result) => {
        if (result) {
          if (hasDialog) {
            this.openDialog();
          } else {
            this.submit();
          }
        } else {
          this.clickedOneTime = false;
          return false;
        }
      });
    } else {
      this.submit();
    }
  }

  submit(): void {
    this.clickedOneTime = true;
    this.executeCustomJS();
    if (typeof this.bphChange.isSubmit === 'undefined' || this.bphChange.isSubmit === true) {
      if (!this.isFormbuilderModeEnabled) {
        const data: any = {};
        const objToPost: any = {};
        data.actions = this.confChange.actions || [];
        data.formID = parseInt(this.confChange.formId, 10);
        data.doValidation = this.confChange.doValidation ? this.confChange.doValidation : false;
        if (data.doValidation) {
          data.sectionId = this.confChange.isValidateSectionOnly ? this.selectedSectionId : undefined;
        }
        data.prj = this.globalPrj;
        data.prj.connectedUser = this.loginAuthenticationService.userProfile.id;
        objToPost.data = data;
        objToPost.outputAction = this.confChange.outputAction;
        objToPost.url = this.confChange.url;
        this.confChange.responseType = this.confChange.responseType ? this.confChange.responseType : 'json';
        this.http.post('api/callworkflow', objToPost, this.confChange.responseType === 'blob' ?
          {observe: 'response', responseType: this.confChange.responseType} :
          {responseType: this.confChange.responseType}).subscribe((_data: any) => {
          if (this.confChange.responseType === 'blob') { // Check the content type of the response.
            const result: HttpResponse<Blob> = _data;
            this.clickedOneTime = false;
            ECaseUtils.generateHyperLinkForDownload(result);
          } else {
            const result: any = _data;
            if (result.status) { // Success response from the server
              if (result.isFormValid || !data.doValidation) { // Check if the form is valid / Don't check it if the validation is disabled on a submit button.
                if (this.confChange.afterSubmitRedirectionPageUrl) { // Check if the redirection url is provided that will navigate user to the page, post click.
                  if (this.enableOutputEvent) {  // Check if output event is enabled which will run user specific custom logic
                    this.emitOutputEvent.emit(this.confChange);
                  } else {
                    // don't show the success message if actions only contains doNothing action or success dialog is enabled otherwise show snackbar
                    if (!(this.confChange.actions.length === 1 && this.confChange.actions.indexOf('doNothing') !== -1) && !this.confChange.isOnSuccessDialogEnabled) {
                      this.eCaseSnackBarService.showComponent('success', ECaseUtils.getTranslatedValueFromKey(this.translate, result.message, this.selectedLanguage));
                    }
                    if (this.confChange.isOnSuccessDialogEnabled) {
                      this.openOnSuccessDialog();
                    }
                    this.pendingChangesGuard.isPristine = true;
                    if (this.confChange.afterSubmitRedirectionPageUrl && this.confChange.afterSubmitRedirectionPageUrl !== '') {
                      this.projectService.isBackClicked = true;
                      if (this.globalPrj.applicationId && (this.confChange.afterSubmitRedirectionPageUrl.toString().endsWith('/inprogress') ||
                        this.confChange.afterSubmitRedirectionPageUrl.toString().endsWith('/applications'))) {
                        this.router.navigate([this.confChange.afterSubmitRedirectionPageUrl + '/' + this.globalPrj.applicationId]).then(() => {});
                      } else {
                        this.router.navigate([this.confChange.afterSubmitRedirectionPageUrl]).then(() => {});
                      }
                    }
                    this.executeAfterResponseJS(result);
                  }
                } else {
                  if (result.prj) {
                    this.confChange.prj = result.prj;
                    this.globalPrj = result.prj;
                  }

                  if (!this.confChange.actions.includes('saveandcontinue')) {
                    this.confChange.operation = 'Validate';
                    this.confChange.noValidationMessage = true;
                    this.refreshDataService.throwValidationError([]); // Pass empty array as validation errors, this will update section mat icons to valid sections
                    this.refreshDataService.saveForm(this.confChange);
                  } else {
                    this.globalPrj[this.selectedSectionId].fbValid = true;
                  }

                  if (this.isItemBefore('submit', 'save', this.confChange.actions)) {
                    this.refreshDataService.saveForm(this.confChange);
                  }
                  if (this.isItemBefore('submit', 'saveandcontinue', this.confChange.actions)) {
                    this.refreshDataService.saveForm(this.confChange);
                  }

                  this.eCaseSnackBarService.showComponent('success', ECaseUtils.getTranslatedValueFromKey(this.translate, result.message, this.selectedLanguage));
                  this.executeAfterResponseJS(result);
                }
              } else {
                this.executeFailureJS();
                this.refreshDataService.saveForm({'operation' : 'Validate', 'isHideSnackBar' : this.confChange.isHideSnackBar});
                this.refreshDataService.saveForm({'operation' : 'Save' , 'isHideSnackBar' : this.confChange.isHideSnackBar});
                if (this.confChange.validationType === 1) {  // Don't open validation dialog
                  const globalPrj = this.globalPrj;
                  const globalConf = this.globalConf;
                  const globalComponent: any = this;
                  globalComponent.prj = this.globalPrj;
                  globalComponent.selectedSection = this.selectedSectionId;
                  globalComponent.globalMessage = {};
                  eval(result.validationJs);
                } else {
                  this.refreshDataService.throwValidationError(result.validationArray); // Open validation dialog if the form validation failed
                }
                if (result.message && result.message.includes('ecase.formbuilder')) {
                  this.eCaseSnackBarService.showComponent('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, result.message, this.selectedLanguage));
                }
              }
              this.clickedOneTime = false;
            } else {
              this.eCaseSnackBarService.showComponent('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, result.message, this.selectedLanguage));
            }
          }
        });
      } else {
       this.clickedOneTime = false;
        this.eCaseSnackBarService.showComponent('failure', ECaseUtils.getTranslatedValueFromKey(this.translate,
          'ecase.common.submitdisabledinformbuildermode', this.selectedLanguage));
      }
    } else {
      this.clickedOneTime = false;
    }
    setTimeout(() => { this.clickedOneTime = false; }, (this.confChange.disabledTime * 1000));
  }

  openOnSuccessDialog(): void {
    console.log('666666666666666666');
    console.log(this.confChange);
    console.log(this.globalConf);
    console.log(this.globalPrj);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.successDialogText,
          DialogTextTwo: this.confChange.successDialogTextTwo,
          applicationId: this.globalPrj.applicationId,
          showConfirmOption: false,
          showActionButtons: false,
          selectedLanguage: this.selectedLanguage
        }
    });
    setTimeout(() => {
      dialogRef.close();
    }, 5000);

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.addtionalDialogContent,
          DialogTextTwo: this.confChange.addtionalDialogContentTwo,
          applicationId: this.globalPrj.applicationId,
          showConfirmOption: true,
          selectedLanguage: this.selectedLanguage
        }
    });
    dialogRef.afterClosed().subscribe((decision) => {
      if (decision) {
        this.saveAndSubmit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
  }
}
