<div *ngIf="isValidHtml && !bphChange.hide && bphChange.globalShowDetail" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">

  <div class="formLabel">
    <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
      <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
      <br>
    </ng-container>
    <span *ngIf="confChange.isRequired" style="color: red">* </span><span [innerHTML]="confChange.label[selectedLanguage]"></span>
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
     <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
               matTooltipClass="tooltip">help_outline</mat-icon>
  </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
      <button type="button" mat-icon-button (click)="openDialog()"  style="margin-left: -10px;margin-bottom: -10px;">
     <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon></button>
  </span>

  </div>
  <div>
    <div [style.width]="getWidthFromMaxlength() " [id]="selectedSectionId + '_'+ confChange.name">
      <ng-container *ngIf="confChange.hasRealtimeFormatting">
        <mat-form-field appearance="outline" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''"                        [style.width.px]="(confChange.blockWidthPx) ? confChange.blockWidthPx : ''"
                        [style.width.%]="(confChange.blockWidthPerct) ? confChange.blockWidthPerct : ''">
          <ng-container *ngIf="!(confChange.inputType === 'percentage' || confChange.inputType === 'number')">
            <input [id]="confChange.name" (keydown)="inputValidation($event)" (input)="changeValueEvent($event);"  [(ngModel)]="bphChange.value" [attr.aria-label]="confChange.label[selectedLanguage]"
                   [style.text-align]="confChange.textAlignment"
                   [attr.maxlength]="confChange.maxlength" [type]="confChange.inputType === 'percentage' ? 'number' : confChange.inputType"
                   [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled" [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                   eCaseNumberField [lang]="selectedLanguage" [value]="bphChange.value" [isCurrency]="!!confChange.currency" [isNegativeAllowed]="confChange.isNegativeAllowed"
                   matInput>
          </ng-container>
          <ng-container *ngIf="confChange.inputType === 'percentage' || confChange.inputType === 'number'">
            <input eCaseNDecimalNumber="{{confChange.isDecimalNotAllowed ? 0 : ((confChange.decimalPlacesAllowed || confChange.decimalPlacesAllowed === 0) ? confChange.decimalPlacesAllowed : 2)}}"
                   eCaseNumberField [lang]="selectedLanguage" [value]="bphChange.value" [isCurrency]="!!confChange.currency" [isNegativeAllowed]="confChange.isNegativeAllowed"
                   [decimalPlacesAllowed]="confChange.isDecimalNotAllowed"
                   [id]="confChange.name" (keydown)="inputValidation($event)" (input)="changeValueEvent($event);"  [attr.aria-label]="confChange.label[selectedLanguage]"
                   [style.text-align]="confChange.textAlignment"
                   [attr.maxlength]="confChange.maxlength" type="text"
                   [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled" [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                   matInput>
          </ng-container>
          <span *ngIf="confChange.inputSuffix" matSuffix
                [style.padding-left]="(confChange.inputSuffix === '$' || confChange.inputSuffix === '%' ) ? '5px' : ''">{{getTranslatedValue(confChange.inputSuffix)}}</span>
          <!--[type]="confChange.inputType ? confChange.inputType : 'text'"-->
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="!confChange.hasRealtimeFormatting">
        <mat-form-field appearance="outline" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''"
                        [style.width.px]="(confChange.blockWidthPx) ? confChange.blockWidthPx : ''"
                        [style.width.%]="(confChange.blockWidthPerct) ? confChange.blockWidthPerct : ''">
          <ng-container *ngIf="!(confChange.inputType === 'percentage' || confChange.inputType === 'number')">
            <input [id]="confChange.name" (keydown)="inputValidation($event)" (input)="changeValueEvent($event);"  [(ngModel)]="bphChange.value" [attr.aria-label]="confChange.label[selectedLanguage]"
                   [style.text-align]="confChange.textAlignment"
                   [attr.maxlength]="confChange.maxlength" [type]="confChange.inputType === 'percentage' ? 'number' : confChange.inputType"
                   [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled" [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                   matInput>
          </ng-container>
          <ng-container *ngIf="confChange.inputType === 'percentage' || confChange.inputType === 'number'">
            <input eCaseNDecimalNumber="{{confChange.isDecimalNotAllowed ? 0 : ((confChange.decimalPlacesAllowed || confChange.decimalPlacesAllowed === 0) ? confChange.decimalPlacesAllowed : 2)}}"
                   [id]="confChange.name" (keydown)="inputValidation($event)" (input)="changeValueEvent($event)"
                   [(ngModel)]="bphChange.value" [attr.aria-label]="confChange.label[selectedLanguage]"
                   [style.text-align]="confChange.textAlignment"
                   [attr.maxlength]="confChange.maxlength" type="text"
                   [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled" [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                   matInput>
          </ng-container>
          <span *ngIf="confChange.inputSuffix" matSuffix
                [style.padding-left]="(confChange.inputSuffix === '$' || confChange.inputSuffix === '%' ) ? '5px' : ''">{{getTranslatedValue(confChange.inputSuffix)}}</span>
          <!--[type]="confChange.inputType ? confChange.inputType : 'text'"-->
        </mat-form-field>
      </ng-container>
      <div  *ngIf="!(confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true') && !confChange.hideCharacterCount"  class="field"  style="text-align: right;">
        <p *ngIf="confChange.maxlength && !confChange.countUnit"  [style.margin-top]="bphChange?.error[selectedLanguage] ? '0' : '-20px'" class="counter-style">{{getTextLength(bphChange.value)}} / {{confChange.maxlength}}</p>
        <p *ngIf="confChange.maxlength && confChange.countUnit ==='ecase.common.words'" style="margin-top: -20px" class="counter-style"  >{{getWordCount(bphChange.value)}} /
          {{confChange.maxlength}} {{getTranslatedValue('ecase.common.words')}}</p>
        <p *ngIf="confChange.maxlength && confChange.countUnit ==='ecase.common.characters'" style="margin-top: -20px" class="counter-style" >{{getTextLength(bphChange.value)}} /
          {{confChange.maxlength}} {{getTranslatedValue('ecase.common.characters')}}</p>
      </div>
    </div>
    <span *ngIf="bphChange?.error[selectedLanguage]" class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</div>

<td [id] = "'td_' + confChange.name + '_' + rowNumber" *ngIf="isValidTable && !row.isTotal && !bphChange.hide" style="width: 10%;padding-right:10px !important;padding-left:10px"
    [style.border-top]="confChange.borderTop ? confChange.borderTop : 0"
    [style.border-bottom]="confChange.borderBottom ? confChange.borderBottom : 0"
    [style.border-right]="confChange.borderRight ? confChange.borderRight : 0"
    [style.border-left]="confChange.borderLeft ? confChange.borderLeft : 0"
    [style.padding-bottom]="bphChange.paddingBottom ? bphChange.paddingBottom : ''"
    [style.padding-bottom]="bphChange.error[selectedLanguage] ? '': 'bphChange.paddingBottom'" [style.text-align]="textAlignment"
    [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'" >
  <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}" [style.width]="(confChange.blockWidthPx || confChange.blockWidthPerct) ? '' : getWidthFromMaxlength(true)"
       [style.margin-top]="(!(confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'))&& confChange.maxlength ? '10px' : '0'"
       [style.margin-bottom]="(!(confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'))&& confChange.maxlength ? '3px' : '0'"
   style="margin-left: auto;margin-right: auto;">
    <ng-container *ngIf="confChange.hasRealtimeFormatting">
      <mat-form-field appearance="outline" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''"
                      [style.width.px]="(confChange.blockWidthPx) ? confChange.blockWidthPx : ''"
                      [style.width.%]="(confChange.blockWidthPerct) ? confChange.blockWidthPerct : ''">
        <ng-container *ngIf="!(confChange.inputType === 'percentage' || confChange.inputType === 'number')">
          <input [id]="confChange.name" (keydown)="inputValidation($event)" (input)="changeValueEvent($event);"  [(ngModel)]="bphChange.value" [attr.aria-label]="confChange.label[selectedLanguage]"
                 [style.text-align]="confChange.textAlignment"
                 [attr.maxlength]="confChange.maxlength" [type]="confChange.inputType === 'percentage' ? 'number' : confChange.inputType"
                 [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled" [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                 eCaseNumberField [lang]="selectedLanguage" [value]="bphChange.value" [isCurrency]="!!confChange.currency" [isNegativeAllowed]="false"
                 matInput>
        </ng-container>
        <ng-container *ngIf="confChange.inputType === 'percentage' || confChange.inputType === 'number'">
          <input eCaseNDecimalNumber="{{confChange.isDecimalNotAllowed ? 0 : ((confChange.decimalPlacesAllowed || confChange.decimalPlacesAllowed === 0) ? confChange.decimalPlacesAllowed : 2)}}"
                 eCaseNumberField [lang]="selectedLanguage" [value]="bphChange.value" [isCurrency]="!!confChange.currency" [isNegativeAllowed]="confChange.isNegativeAllowed"
                 [decimalPlacesAllowed]="confChange.isDecimalNotAllowed"
                 [id]="confChange.name" (keydown)="inputValidation($event)" (input)="changeValueEvent($event);"  [attr.aria-label]="confChange.label[selectedLanguage]"
                 [style.text-align]="confChange.textAlignment"
                 [attr.maxlength]="confChange.maxlength" type="text"
                 [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled" [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                 matInput>
        </ng-container>
        <span *ngIf="confChange.inputSuffix" matSuffix
              [style.padding-left]="(confChange.inputSuffix === '$' || confChange.inputSuffix === '%' ) ? '5px' : ''">{{getTranslatedValue(confChange.inputSuffix)}}</span>
        <!--[type]="confChange.inputType ? confChange.inputType : 'text'"-->
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="!confChange.hasRealtimeFormatting">
      <mat-form-field appearance="outline" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''"
                      [style.width.px]="(confChange.blockWidthPx) ? confChange.blockWidthPx : ''"
                      [style.width.%]="(confChange.blockWidthPerct) ? confChange.blockWidthPerct : ''">
        <ng-container *ngIf="!(confChange.inputType === 'percentage' || confChange.inputType === 'number')">
          <input [id]="confChange.name" (keydown)="inputValidation($event)" (input)="changeValueEvent($event);"  [(ngModel)]="bphChange.value" [attr.aria-label]="confChange.label[selectedLanguage]"
                 [style.text-align]="confChange.textAlignment"
                 [attr.maxlength]="confChange.maxlength" [type]="confChange.inputType === 'percentage' ? 'number' : confChange.inputType"
                 [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled" [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                 matInput>
        </ng-container>
        <ng-container *ngIf="confChange.inputType === 'percentage' || confChange.inputType === 'number'">
          <input eCaseNDecimalNumber="{{confChange.isDecimalNotAllowed ? 0 : ((confChange.decimalPlacesAllowed || confChange.decimalPlacesAllowed === 0) ? confChange.decimalPlacesAllowed : 2)}}"
                 [id]="confChange.name" (keydown)="inputValidation($event)" (input)="changeValueEvent($event);"  [(ngModel)]="bphChange.value" [attr.aria-label]="confChange.label[selectedLanguage]"
                 [style.text-align]="confChange.textAlignment"
                 [attr.maxlength]="confChange.maxlength" type="text"
                 [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled" [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                 matInput>
        </ng-container>
        <span *ngIf="confChange.inputSuffix" matSuffix
              [style.padding-left]="(confChange.inputSuffix === '$' || confChange.inputSuffix === '%' ) ? '5px' : ''">{{getTranslatedValue(confChange.inputSuffix)}}</span>
        <!--[type]="confChange.inputType ? confChange.inputType : 'text'"-->
      </mat-form-field>
    </ng-container>

    <div *ngIf="!(confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true') && !confChange.hideCharacterCount" class="field"  style="text-align: right;"
         [style.width]="getWidthForCounter()">
      <p *ngIf="confChange.maxlength && !confChange.countUnit" class="counter-style">{{getTextLength(bphChange.value)}} / {{confChange.maxlength}}</p>
      <p *ngIf="confChange.maxlength && confChange.countUnit ==='ecase.common.words'" class="counter-style"  >{{getWordCount(bphChange.value)}} /
        {{confChange.maxlength}} {{getTranslatedValue('ecase.common.words')}}</p>
      <p *ngIf="confChange.maxlength && confChange.countUnit ==='ecase.common.characters'"  class="counter-style" >{{getTextLength(bphChange.value)}} /
        {{confChange.maxlength}} {{getTranslatedValue('ecase.common.characters')}}</p>
    </div>
    <div class="field">
      <span class="formFieldError" *ngIf="bphChange?.error[selectedLanguage]">{{bphChange.error[selectedLanguage]}}</span>
    </div>

  </div>
</td>


<td *ngIf="isValidTableReadonly && !row.isTotal && !bphChange.hideCell && !confChange.hideCell"
    [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    [attr.rowspan]="bphChange.rowspan" [style.text-align]="textAlignment"
    [style.padding-left]="paddingLeft"
    style="border:0;width: 1%;padding-left:10px;padding-right:10px">
  <div
       [style.text-align]="confChange.textAlignment ? confChange.textAlignment : 'center'"
       [style.padding]="confChange.textAlignment ? '10px' : ''"
       class="field hide_{{bphChange.show}} {{bphChange.error_class}}">
    {{getBphValue()}}
    <span *ngIf="bphChange?.error[selectedLanguage]" class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</td>
